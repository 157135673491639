<template>
  <div class="relative" :class="count ? 'active' : ''">
    <div class="redot" v-if="count">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.5" cy="7.5" r="7.5" fill="#D92D20" />
      </svg>
    </div>
    <div
      class="livestream-redot z-10"
      v-if="isStatus === 1"
    >{{ $t("conference.lbl_is_conferencing") }}</div>
    <div class="item-w relative overflow-hidden">
      <div :set="timeData=getStartTime()" class="flex flex-col lg:flex-row gap-2">
        <div
          class="text-white flex flex-col gap-1 items-center justify-center px-1 py-2"
          style="border-radius: 4px;
                max-height:76px;
                max-width:62px"
          :style="getBackgroundColor()"
          v-if="timeData"
        >
          <div class="fs-20">{{ timeData.hours }}</div>
          <div class="fs-14">{{ timeData.date }}</div>
        </div>
        <div class="flex-1">
          <p
            class="fs-18 mb-1 max-line-2"
            :class="count ? 'txt-pri fw-700' : 'txt-grey-900 fw-500'"
          >
            <span
              v-if="!is_session"
              class="align-bottom leading-tight"
            >{{ getMultilangValue(circle) ? getMultilangValue(circle).name : circle.name }}</span>
            <span
              v-else
              class="align-bottom leading-tight"
            >{{ getMultilangValue(circle) ? (getMultilangValue(circle).name + ': ' + getMultilangValue(circle).location) : (circle.name + ': ' + circle.location) }}</span>
          </p>
          <p
            v-if="!is_session"
            class="txt-grey-900 fs-14 max-line-1 mb-1"
            :class="count ? 'txt-pri' : 'txt-grey-900'"
          >{{ getMultilangValue(circle) ? getMultilangValue(circle).location : circle.location }}</p>
          <p
            class="txt-grey-900 fs-14 fw-400 mb-0 max-line-3 h-40px"
            :class="count ? 'fw-500 txt-pri' : 'fw-400 txt-grey-900'"
          >{{ getMultilangValue(circle) ? getMultilangValue(circle).description : circle.description }}</p>
        </div>
      </div>
      <div>
        <!-- <div class="cs-img" v-if="img" :style="'background-image: url(' + (img) + ')'" /> -->
        <div class="cs-img" v-if="svg" v-html="svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'

export default {
  name: 'CircleListItem',
  props: ['circle', 'loading', 'is_session', 'img'],
  data () {
    return {
      count: 0,
      svg: '',
      isStatus: 0 // 1: currentEvents, 2: upcomingEvents, 3: pastEvents, 0: none
    }
  },
  computed: {
    lang () {
      return LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
    }
  },
  created () {
    this.svgImgColor()
  },
  mounted () {
    const currentTimestamp = Date.now()
    const endTime = this.circle?.end_time

    if (currentTimestamp < this.circle?.start_time) {
      this.isStatus = 2
    } else if (
      currentTimestamp >= this.circle?.start_time &&
      currentTimestamp <= endTime
    ) {
      this.isStatus = 1
    } else {
      this.isStatus = 3
    }
  },
  methods: {
    getMultilangValue (data) {
      return data.circle_language?.find(
        item => (item.Lang || item.lang) === this.lang
      )
    },
    getBackgroundColor () {
      if (!this.circle.bg_color) return ''
      const background = this.circle?.bg_color?.split(', ')
      if (!background?.length > 2) return ''

      return `background: linear-gradient(180deg, #${background[0]} 0%, #${background[1]} 100%)`
    },
    getStartTime () {
      if (!this.circle.start_time) return ''

      const startTime = window.moment(this.circle.start_time)

      return {
        hours: startTime.format('HH:mm'),
        date: startTime.format('DD/MM'),
        dateTime: startTime.format('HH:mm DD-MM-YYYY'),
        dateFull: startTime.format('YYYY-MM-DD')
      }
    },
    svgImgColor () {
      const svgImg = this.img

      if (!svgImg) return

      const element = document.createElement('div')
      element.innerHTML = svgImg
      const stopElement = element?.getElementsByTagName('stop')
      const pathElement = element.getElementsByTagName('path')[0]
      const linearGradientElement = element.getElementsByTagName(
        'linearGradient'
      )[0]

      pathElement.setAttribute('fill', `url(#${this.circle.id})`)
      linearGradientElement.setAttribute('id', `${this.circle.id}`)

      if (!stopElement) return svgImg
      if (stopElement?.length !== 2 || !this.circle.bg_color) return svgImg
      const background = this.circle?.bg_color?.split(', ')

      for (let i = 0; i < stopElement.length; i++) {
        if (i === 0) {
          stopElement[0].setAttribute('stop-color', `#${background[0]}`)
        } else {
          stopElement[i].setAttribute('stop-color', `#${background[1]}`)
        }
      }

      this.svg = element.outerHTML
    }
  }
}
</script>

  <style scoped>
.item-w {
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
  min-height: 196px;
}

.mb-24 {
  margin-bottom: 24px;
}
.h-40px {
  height: 40px;
}
.h-196px {
  height: 196px;
}
.active {
  position: relative;
}
.redot {
  top: 10px;
  right: 10px;
  position: absolute;
}
.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 5px 10px;
  top: -15px;
  right: -15px;
  position: absolute;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
}
</style>
<style scoped lang="scss">
.txt-pri {
  color: #20419b !important;
}
.bg-pri {
  background-color: #20419b !important;
}

.cs-img {
  width: 100%;
  max-width: 150px;
  max-height: 170px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -38px;
  right: -36px;
}
</style>
